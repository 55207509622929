import { getLocaleDateString } from "../api/DateUtils"
import { CalendarIcon } from "@heroicons/react/outline"
import { useEffect } from "react"
import Datepicker from '@themesberg/tailwind-datepicker/js/Datepicker.js'
const DatePicker = ({ label, id, name, autoComplete, placeholder, error, requiredErrorMessage, register, required }) => {

    useEffect(() => {
        new Datepicker(document.getElementById(id), {
            format: "yyyy-mm-dd"
        })
    }, [id]);

    return (
        <>
            <label htmlFor={id} className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <div className="relative">
                <div className='flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none'>
                    <CalendarIcon className='w-5 h-5 text-gray-500 dark:text-gray-400' />
                </div>
                <input
                    id={id}
                    datepicker="true"
                    datepicker-format={getLocaleDateString().toLowerCase()}
                    {...register(name, { required })}
                    autoComplete={autoComplete}
                    placeholder={placeholder}
                    className={`mt-1 block w-full py-2 px-3 border pl-10 ${error ? 'border-red-600' : 'border-gray-300'} bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                />
            </div>
            {error && (
                <div className="text-sm font-medium text-red-600 ">{requiredErrorMessage}</div>
            )}
        </>
    )
}
export default DatePicker