
import { ShieldCheckIcon, ClockIcon, CashIcon, CheckIcon } from '@heroicons/react/outline'
import FreeAgentLogo from './svg/FreeAgentLogo'
import GoogleLogo from './svg/GoogleLogo'
import QuickbooksLogo from './svg/QuickbooksLogo'
const Home = () => {
    return (
        <>
            {/* Section 1 */}
            <section className="px-2 pt-20 md:pt-32 bg-white md:px-0">
                <div className="container items-center max-w-6xl px-5 mx-auto space-y-6 text-center">
                    <h1 className="text-5xl font-extrabold tracking-tight text-left text-gray-900 sm:text-5xl md:text-6xl md:text-center">
                        <span className="block">Invoice from your <span className="text-indigo-600">Calendar</span></span>
                    </h1>
                    <p className="w-full mx-auto text-base text-left text-gray-500 md:max-w-md sm:text-lg lg:text-2xl md:max-w-3xl md:text-center">
                        Save time invoicing your clients by using your events on your calendar. We create the incoice in your chosen accounting software.
                    </p>
                    <div className="relative flex flex-col justify-center md:flex-row md:space-x-4">
                        <a href="/sign-up?plan=free" className="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-indigo-600 rounded-md md:mb-0 hover:bg-indigo-700 md:w-auto">
                            Try It Free
                            <svg className="w-5 h-5 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                                <polyline points="12 5 19 12 12 19"></polyline>
                            </svg>
                        </a>
                        {/* 
                        <a href="#_" className="flex items-center px-6 py-3 text-gray-500 bg-gray-100 rounded-md hover:bg-gray-200 hover:text-gray-600">
                            Learn More
                        </a>
                        */}
                    </div>
                </div>
                <div className="container items-center max-w-4xl lg:px-64 px-32 pb-20 mx-auto mt-16 text-center">
                    <img className="mx-auto sm:max-w-sm lg:max-w-full" src="img/085-calendar-colour.png" alt="woman with a calendar" />

                </div>
            </section>
            <section className="bg-white pt-7 pb-14">
                <div className="container px-8 mx-auto sm:px-14 lg:px-20">
                    <h1 className="text-sm font-bold tracking-wide text-center text-gray-800 uppercase mb-7">Works with:</h1>
                    <div className="flex grid items-center justify-center grid-cols-12 gap-y-8 space-x-5">

                        <div className="flex items-center justify-center col-span-4">
                            <GoogleLogo className='h-8 mt-2 text-gray-500 fill-current' />
                        </div>
                        <div className="flex items-center justify-center col-span-4">
                            <FreeAgentLogo className='h-8 text-gray-500 fill-current' />
                        </div>
                        <div className="flex items-center justify-center col-span-4">
                            <QuickbooksLogo className='h-8 text-gray-500 fill-current' />
                        </div>
                    </div>
                    <p className="w-full mx-auto text-base text-center text-gray-500 text-md p-10 pb-0">
                        We are compatible with Google Calendar&trade;, Quickbooks&reg; Online and FreeAgent
                    </p>
                </div>
            </section>

            <section className="py-20 bg-gray-100">
                <div className="container items-center max-w-6xl px-4 px-10 mx-auto sm:px-20 md:px-32 lg:px-16">
                    <div className="flex flex-wrap items-center -mx-3">
                        <div className="order-1 w-full px-3 lg:w-1/2 lg:order-0">
                            <div className="w-full lg:max-w-md">
                                <h2 className="mb-4 pr-10 text-3xl font-bold leading-tight tracking-tight sm:text-4xl font-heading">Create Invoices in no time</h2>
                                <p className="mb-4 font-medium tracking-tight text-gray-400 xl:mb-6">It's never been easier to create your invoices. Link your calendar and your accounting software. Search for your events and invoice. It's as simple as that!</p>
                                <ul>
                                    <li className="flex items-center py-2 space-x-2 sm:space-x-4 xl:py-3">
                                        <CashIcon className='w-8 h-8 text-green-500'></CashIcon>
                                        <span className="font-medium text-gray-500">Invoice quicker and get paid sooner</span>
                                    </li>
                                    <li className="flex items-center py-2 space-x-4 xl:py-3">
                                        <ClockIcon className='w-8 h-8 text-pink-500'></ClockIcon>
                                        <span className="font-medium text-gray-500">Spend less time with admin, spend more time delivering</span>
                                    </li>
                                    <li className="flex items-center py-2 space-x-4 xl:py-3">
                                        <ShieldCheckIcon className='w-8 h-8 text-yellow-500'></ShieldCheckIcon>
                                        <span className="font-medium text-gray-500">We encrypt all transactions and never store your data.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-full px-1 mb-12 lg:w-1/2 order-0 lg:order-1 lg:mb-0">
                            <img src="/img/screenshot-crop.png" alt='screnshot of ok invoices' />
                        </div>
                    </div>
                </div>
            </section>



            {/*  Section 4 */}
            <section id='pricing' className="py-8 leading-7 text-gray-900 bg-white sm:py-12 md:py-16 lg:py-24">
                <div className="box-border px-4 mx-auto border-solid sm:px-6 md:px-6 lg:px-8 max-w-7xl">
                    <div className="flex flex-col items-center leading-7 text-center text-gray-900 border-0 border-gray-200">
                        <h2 className="box-border m-0 text-3xl font-semibold leading-tight tracking-tight text-black border-solid sm:text-4xl md:text-5xl">
                            Our pricing
                        </h2>
                        <p className="box-border mt-2 text-xl text-gray-900 border-solid sm:text-2xl">
                            Simple, Transparent pricing.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 gap-4 mt-4 leading-7 text-gray-900 border-0 border-gray-200 sm:mt-6 sm:gap-6 md:mt-8 md:gap-0 lg:grid-cols-2">

                        <div className="relative z-10 flex flex-col items-center max-w-md p-4 mx-auto my-0 border border-solid rounded-lg lg:-mr-3 sm:my-0 sm:p-6 md:my-8 md:p-8">
                            <h3 className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 sm:text-3xl md:text-4xl">
                                Free trial
                            </h3>
                            <div className="flex items-end mt-6 leading-7 text-gray-900 border-0 border-gray-200">
                                <p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
                                    $0
                                </p>
                            </div>
                            <ul className="flex-1 p-0 mt-4 ml-5 leading-7 text-gray-900 border-0 border-gray-200">
                                <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
                                    <CheckIcon className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-600 sm:h-5 sm:w-5 md:h-6 md:w-6" />
                                    7 days free
                                </li>
                                <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
                                    <CheckIcon className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-600 sm:h-5 sm:w-5 md:h-6 md:w-6" />
                                    No credit card required
                                </li>
                                <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
                                    <CheckIcon className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-600 sm:h-5 sm:w-5 md:h-6 md:w-6" />
                                    Invoice as much as you want
                                </li>
                            </ul>
                            <a href='/sign-up?plan=free' className="inline-flex justify-center w-full px-4 py-3 mt-8 font-sans text-sm leading-none text-center text-blue-600 no-underline bg-transparent border border-blue-600 rounded-md cursor-pointer hover:bg-blue-700 hover:border-blue-700 hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700 focus-within:text-white sm:text-base md:text-lg">
                                Select Plan
                            </a>
                        </div>
                        <div className="relative z-20 flex flex-col items-center max-w-md p-4 mx-auto my-0 bg-white border-4 border-blue-600 border-solid rounded-lg sm:p-6 md:px-8 md:py-16">
                            <h3 className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 sm:text-3xl md:text-4xl">
                                Premium
                            </h3>
                            <div className="flex items-end mt-6 leading-7 text-gray-900 border-0 border-gray-200">
                                <p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
                                    $15
                                </p>
                                <p className="box-border m-0 border-solid" style={{ borderImage: 'initial' }}>
                                    / month
                                </p>
                            </div>
                            <ul className="flex-1 p-0 mt-4 leading-7 text-gray-900 border-0 border-gray-200">
                                <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
                                    <CheckIcon className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-600 sm:h-5 sm:w-5 md:h-6 md:w-6" />
                                    Unlimited calendar searches
                                </li>
                                <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
                                    <svg className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-600 sm:h-5 sm:w-5 md:h-6 md:w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    Unlimited invoices
                                </li>
                                <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
                                    <CheckIcon className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-600 sm:h-5 sm:w-5 md:h-6 md:w-6" />
                                    Premium Support
                                </li>
                            </ul>
                            <a href='/sign-up?plan=premium' className="inline-flex justify-center w-full px-4 py-3 mt-8 font-sans text-sm leading-none text-center text-white no-underline bg-blue-600 border rounded-md cursor-pointer hover:bg-blue-700 hover:border-blue-700 hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700 focus-within:text-white sm:text-base md:text-lg">
                                Select Plan
                            </a>
                        </div>

                    </div>
                </div>
            </section>

            <section className="px-2 py-32 bg-white md:px-0">
                <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
                    <div className="flex flex-wrap items-center sm:-mx-3">
                        <div className="w-full md:w-1/2 md:px-3">
                            <div className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
                                <h1 className="text-5xl sm:text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-4xl lg:text-5xl xl:text-7xl">
                                    <span className="block xl:inline">See how simple it is </span>
                                    <span className="block text-indigo-600 xl:inline">Try it free!</span>
                                </h1>
                                <p className="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">It's so easy to start. <br />OK Invoices is compatible with Google Calendar&trade; and with FreeAgent and Quickbooks&reg; Online accounting software. You just have link your accounts and start invoicing!</p>
                                <div className="relative flex flex-col sm:flex-row sm:space-x-4">
                                    <a href="/sign-up?plan=free" className="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-indigo-600 rounded-md sm:mb-0 hover:bg-indigo-700 sm:w-auto">
                                        Try It Free
                                        <svg className="w-5 h-5 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                    </a>
                                    {/* 
                                    <a href="#_" className="flex items-center px-6 py-3 text-gray-500 bg-gray-100 rounded-md hover:bg-gray-200 hover:text-gray-600">
                                        Learn More
                                    </a>
                                    */}
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2">
                            <div className="w-full h-auto overflow-hidden px-20">

                                <img src="img/011-drawkit-drawing-man-colour.png" alt="man writing" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Home