import { baseBackEndUri } from "../settings"
import { checkAuthorized, defaultHeaders } from "./Utils"

const freeAgentAddress = baseBackEndUri + '/auth/freeagent'
const xeroAddress = baseBackEndUri + '/auth/xero'
const quickbooksAddress = baseBackEndUri + '/auth/quickbooks'
const googleAddress = baseBackEndUri + '/auth/google'
const statusAddress = baseBackEndUri + '/auth/status'

export const getFreeAgentToken = async () => {
    return getRequest(freeAgentAddress)
}

export const getXeroToken = async () => {
    return getRequest(xeroAddress)
}

export const getQuickbooksToken = async () => {
    return getRequest(quickbooksAddress)
}

export const getGoogleToken = async () => {
    return getRequest(googleAddress)
}

export const deleteToken = async (service) => {
    return deleteRequest(baseBackEndUri + '/auth/' + service)
}

export const getStatus = async () => {
    return getRequest(statusAddress);
}

const deleteRequest = async (address) => {
    const requestOptions = {
        method: 'DELETE',
        headers: defaultHeaders
    };
    const response = await fetch(address, requestOptions);
    return checkAuthorized(response)
}

const getRequest = async (address) => {
    const requestOptions = {
        method: 'GET',
        headers: defaultHeaders
    };
    const response = await fetch(address, requestOptions);
    return checkAuthorized(response)
}
