import './styles/output.css'
import Dashboard from './Dashboard'
import Nav2 from './Nav2'
import AddInvoice from './AddInvoice'
import Invoice from './Invoice'
import Account from './Account'
import Login from './auth/Login'
import SignUp from './auth/SignUp'
import Profile from './auth/Profile'
import Support from './Support'
import Home from './Home'
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Terms from './Terms'
import Privacy from './Privacy'
import GenericNotFound from './GenericNotFound'

function App() {
  return (
    <div className="relative min-h-screen">
      <Nav2 />
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/sign-up" element={<SignUp />} />
          <Route exact path="/add-invoice" element={<AddInvoice />} />
          <Route exact path="/invoice" element={<Invoice />} />
          <Route exact path="/settings" element={<Account />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/support" element={<Support />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path='/*' element={<GenericNotFound />} status={404} />
        </Routes>
      </BrowserRouter>
      {/*  Section 6 */}
      <footer className="bg-white">
        <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
          <nav className="flex flex-wrap justify-center -mx-5 -my-2">
            <div className="px-5 py-2">
              <a href="/#pricing" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                Pricing
              </a>
            </div>
            <div className="px-5 py-2">
              <a href="/terms" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                Terms
              </a>
            </div>
            <div className="px-5 py-2">
              <a href="/privacy" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                Privacy Notice
              </a>
            </div>

            <div className="px-5 py-2">
              <a href="#" id="open_preferences_center" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                Cookies Preferences
              </a>
            </div>
          </nav>
          <p className="mt-8 text-base leading-6 text-center text-gray-400">
            © 2022 OK Invoices Ltd. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
