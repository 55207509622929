import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon, ShieldCheckIcon } from '@heroicons/react/outline'
import { getClients, invoice } from './api/Accounting'
import Message from './elements/Message'
import TextInput from './elements/TextInput'
import Dropdown from './elements/Dropdown'
import { useForm } from 'react-hook-form'

const CreateInvoiceDialog = ({ open = false, setOpen, calendarEvents }) => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [created, setCreated] = useState(false)
    const [invoiceRef, setInvoiceRef] = useState("")
    const [clients, setClients] = useState([])
    const [form, setForm] = useState({})
    const [formErrors, setFormErrors] = useState({})

    const salesTaxStatus = watch('salesTaxStatus')
    const type = watch('type')

    useEffect(() => {
        if (!open) {
            setTimeout(() => {
                setCreated(false)
            }, 500);
        }
    });

    useEffect(() => {
        getClients().then(response => response.json())
            .then(data => data.result.map(client => (
                {
                    value: client.id,
                    text: client.name
                })))
            .then(data => {
                setClients(data)
                if (data) {
                    setForm({ ...form, clientId: data[0].value })
                }
            })
            .catch(error => console.log(error))

    }, []);

    const onSubmit = (data) => {
        if (data.salesTaxStatus === false) {
            data.salesTaxStatus = undefined
        }
        data.events = calendarEvents
        invoice(data).then(response => {
            if (response.ok || response.status === 400) {
                return response.json()
            }
            throw new Error()

        }).then(data => {
            if (data.errors) {
                let errors1 = data.errors.reduce((p, c) => ({ ...p, [c.field]: c.defaultMessage }), {})
                setFormErrors(errors1)
            } else if (data.error) {
                setFormErrors({ global: data.message })
            } else {
                setInvoiceRef(data.result.ref)
                setCreated(true)
            }
        })
            .catch(error => {
                alert("Unable to create invoice")
            })
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={setOpen}>
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="relative w-screen max-w-md">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-500"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-500"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                                        <button
                                            type="button"
                                            className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">Close panel</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                                    <div className={created ? 'hidden' : 'block'}>
                                        <div className="px-4 sm:px-6">
                                            <Dialog.Title className="text-lg font-medium text-gray-900">Create Invoice</Dialog.Title>
                                        </div>
                                        <div className="mt-6 relative flex-1 px-4 sm:px-6">
                                            <Message title="Please fix the following issues" items={Object.values(formErrors)} />
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="mt-6">
                                                    <Dropdown id="clientId"
                                                        label="Client"
                                                        name="clientId"
                                                        autoComplete="clientId"
                                                        register={register}
                                                        required
                                                        items={clients}
                                                        error={errors.clientId} />
                                                </div>
                                                <div className="mt-6">
                                                    <Dropdown id="type"
                                                        label="Style"
                                                        name="type"
                                                        autoComplete="type"
                                                        register={register}
                                                        required
                                                        items={[{ value: "1", text: "One item per event" }, { value: "2", text: "One item only (sums the time of all events)" }]}
                                                        error={errors.type} />

                                                </div>
                                                {type === '2' &&
                                                    (
                                                        <div className="mt-6">
                                                            <TextInput id="description"
                                                                name="description"
                                                                autoComplete="item-description"
                                                                label="Description"
                                                                register={register}
                                                                required
                                                                requiredErrorMessage="Please enter a description"
                                                                error={errors.description} />
                                                        </div>
                                                    )
                                                }
                                                {/* <div className="mt-6">
                                                    <Dropdown id="itemUnit"
                                                        label="Currency"
                                                        name="currency"
                                                        autoComplete="currency"
                                                        register={register}
                                                        required
                                                        error={errors.currency}
                                                        items={[{ value: "GBP", text: "Pounds Sterling" },
                                                        { value: "EUR", text: "Euro" },
                                                        { value: "USD", text: "US Dollar" },
                                                        { value: "CAD", text: "Canadian Dollar" },
                                                        { value: "NZD", text: "New Zealand Dollar" },
                                                        { value: "AUD", text: "Australian Dollar" },
                                                        { disabled: true, value: "--------------", text: "--------------" },
                                                        { value: "AOA", text: "Angolan Kwanza" },
                                                        { value: "ARS", text: "Argentine Peso" },
                                                        { value: "AMD", text: "Armenian Dram" },
                                                        { value: "AWG", text: "Aruban Florin" },
                                                        { value: "AUD", text: "Australian Dollar" },
                                                        { value: "AZN", text: "Azerbaijani Manat" },
                                                        { value: "BSD", text: "Bahamian Dollar" },
                                                        { value: "BHD", text: "Bahraini Dinar" },
                                                        { value: "BDT", text: "Bangladeshi Taka" },
                                                        { value: "BBD", text: "Barbadian Dollar" },
                                                        { value: "BYN", text: "Belarusian Ruble" },
                                                        { value: "BOB", text: "Bolivian Boliviano" },
                                                        { value: "BAM", text: "Bosnian Convertible Marka" },
                                                        { value: "BWP", text: "Botswana Pula" },
                                                        { value: "BRL", text: "Brazil Real" },
                                                        { value: "BND", text: "Bruneian Dollar" },
                                                        { value: "BGN", text: "Bulgarian Lev" },
                                                        { value: "MMK", text: "Burmese Kyat" },
                                                        { value: "XPF", text: "CFP Franc" },
                                                        { value: "CAD", text: "Canadian Dollar" },
                                                        { value: "KYD", text: "Caymanian Dollar" },
                                                        { value: "XAF", text: "Central African CFA Franc" },
                                                        { value: "CLP", text: "Chilean Peso" },
                                                        { value: "CNY", text: "Chinese Yuan" },
                                                        { value: "COP", text: "Colombian Peso" },
                                                        { value: "CRC", text: "Costa Rican Colones" },
                                                        { value: "HRK", text: "Croatian Kuna" },
                                                        { value: "CUC", text: "Cuban Convertible Peso" },
                                                        { value: "CUP", text: "Cuban Peso" },
                                                        { value: "CZK", text: "Czech Koruna" },
                                                        { value: "DKK", text: "Danish Kroner" },
                                                        { value: "DOP", text: "Dominican Peso" },
                                                        { value: "XCD", text: "East Carribean Dollar" },
                                                        { value: "EGP", text: "Egyptian Pound" },
                                                        { value: "ETB", text: "Ethiopian Birr" },
                                                        { value: "EUR", text: "Euro" },
                                                        { value: "FJD", text: "Fijian Dollar" },
                                                        { value: "GEL", text: "Georgian Lari" },
                                                        { value: "GHS", text: "Ghanaian Cedi" },
                                                        { value: "GTQ", text: "Guatemalan Quetzales" },
                                                        { value: "GNF", text: "Guinean Franc" },
                                                        { value: "GYD", text: "Guyanese Dollar" },
                                                        { value: "HNL", text: "Honduran Lempira" },
                                                        { value: "HKD", text: "Hong Kong Dollar" },
                                                        { value: "HUF", text: "Hungarian Forint" },
                                                        { value: "ISK", text: "Icelandic Króna" },
                                                        { value: "INR", text: "Indian Rupee" },
                                                        { value: "IDR", text: "Indonesian Rupiah" },
                                                        { value: "IRR", text: "Iranian Rial" },
                                                        { value: "IQD", text: "Iraqi Dinar" },
                                                        { value: "ILS", text: "Israeli Sheckels" },
                                                        { value: "JMD", text: "Jamaican Dollar" },
                                                        { value: "JPY", text: "Japanese Yen" },
                                                        { value: "JOD", text: "Jordanian Dinar" },
                                                        { value: "KZT", text: "Kazakhstani Tenge" },
                                                        { value: "KES", text: "Kenyan Shilling" },
                                                        { value: "KWD", text: "Kuwaiti Dinar" },
                                                        { value: "LAK", text: "Lao Kip" },
                                                        { value: "LBP", text: "Lebanese Pound" },
                                                        { value: "LYD", text: "Libyan Dinar" },
                                                        { value: "MKD", text: "Macedonian Denar" },
                                                        { value: "MGA", text: "Malagasy Ariary" },
                                                        { value: "MWK", text: "Malawian Kwacha" },
                                                        { value: "MYR", text: "Malaysian Ringgit" },
                                                        { value: "MVR", text: "Maldivian Rufiyaa" },
                                                        { value: "MRU", text: "Mauritanian Ouguiya" },
                                                        { value: "MUR", text: "Mauritian Rupee" },
                                                        { value: "MXN", text: "Mexican Peso" },
                                                        { value: "MDL", text: "Moldovan Leu" },
                                                        { value: "MNT", text: "Mongolian Tugrik" },
                                                        { value: "MAD", text: "Moroccan Dirham" },
                                                        { value: "MZN", text: "Mozambican Metical" },
                                                        { value: "NAD", text: "Namibian Dollar" },
                                                        { value: "NPR", text: "Nepalese Rupees" },
                                                        { value: "ANG", text: "Netherlands Antillean Guilder" },
                                                        { value: "TWD", text: "New Taiwan Dollar" },
                                                        { value: "NZD", text: "New Zealand Dollar" },
                                                        { value: "NIO", text: "Nicaraguan Cordoba" },
                                                        { value: "NGN", text: "Nigerian Naira" },
                                                        { value: "NOK", text: "Norwegian Kroner" },
                                                        { value: "CNH", text: "Offshore Chinese Yuan" },
                                                        { value: "OMR", text: "Omani Rial" },
                                                        { value: "PKR", text: "Pakistani Rupee" },
                                                        { value: "PEN", text: "Peruvian Nuevo Sol" },
                                                        { value: "PHP", text: "Philippine Piso" },
                                                        { value: "PLN", text: "Polish Złoty" },
                                                        { value: "GBP", text: "Pounds Sterling" },
                                                        { value: "QAR", text: "Qatari Riyal" },
                                                        { value: "RON", text: "Romanian New Leu" },
                                                        { value: "RUB", text: "Russian Rouble" },
                                                        { value: "RWF", text: "Rwandan Franc" },
                                                        { value: "STN", text: "Sao Tome and Principe Dobra" },
                                                        { value: "SAR", text: "Saudi Riyal" },
                                                        { value: "RSD", text: "Serbian Dinar" },
                                                        { value: "SCR", text: "Seychelles Rupee" },
                                                        { value: "SGD", text: "Singapore Dollar" },
                                                        { value: "ZAR", text: "South African Rand" },
                                                        { value: "KRW", text: "South Korean Won" },
                                                        { value: "LKR", text: "Sri Lankan Rupee" },
                                                        { value: "SEK", text: "Swedish Kronor" },
                                                        { value: "CHF", text: "Swiss Franc" },
                                                        { value: "TZS", text: "Tanzanian Shilling" },
                                                        { value: "THB", text: "Thai Baht" },
                                                        { value: "TTD", text: "Trinidad and Tobago Dollar" },
                                                        { value: "TND", text: "Tunisian Dinar" },
                                                        { value: "TRY", text: "Turkish Lira" },
                                                        { value: "USD", text: "US Dollar" },
                                                        { value: "UGX", text: "Ugandan Shilling" },
                                                        { value: "UAH", text: "Ukrainian Hryvnia" },
                                                        { value: "AED", text: "United Arab Emirates Dirham" },
                                                        { value: "UYU", text: "Uruguayan Peso" },
                                                        { value: "VUV", text: "Vanuatu Vatu" },
                                                        { value: "VEF", text: "Venezuelan Bolívar" },
                                                        { value: "VND", text: "Vietnamese đồng" },
                                                        { value: "XOF", text: "West African CFA Franc" },
                                                        { value: "ZMW", text: "Zambian Kwacha" },]}  />

                                                </div> */}
                                                <div className="mt-6">
                                                    <TextInput id="itemRate" name="rate" autoComplete="item-rate" label="Rate"
                                                        register={register}
                                                        required
                                                        requiredErrorMessage="Please enter a rate"
                                                        error={errors.rate} />
                                                </div>
                                                {/* 
                                                <div className="mt-6">
                                                    <div className="flex items-start">
                                                        <div className="flex items-center h-5">
                                                            <input
                                                                id="salesTaxStatus"
                                                                {...register("salesTaxStatus")}
                                                                type="checkbox"
                                                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                                value="EXEMPT"
                                                            />
                                                        </div>
                                                        <div className="ml-3 text-sm">
                                                            <label htmlFor="salesTaxStatus" className="font-medium text-gray-700">
                                                                Exempt from Sales Tax
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {!salesTaxStatus &&
                                                    (

                                                        <div className="mt-6">
                                                            <TextInput id="salesTax" name="salesTax" autoComplete="salesTax" label="Sales tax"
                                                                register={register}
                                                                required
                                                                requiredErrorMessage="Please enter a sales tax"
                                                                error={errors.salesTax} />
                                                        </div>
                                                    )
                                                } */}
                                                <div className="mt-6 text-right">
                                                    <button type="submit" className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                        Create Invoice
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <Transition
                                        show={created}
                                        enter="transform transition duration-[400ms]"
                                        enterFrom="opacity-0 scale-50"
                                        enterTo="opacity-100 scale-100"
                                        leave="transform duration-200 transition ease-in-out"
                                        leaveFrom="opacity-100 rotate-0 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <div className="text-green-700 text-center mt-10">
                                            <ShieldCheckIcon className="w-20 inline" />
                                            <h2 className="text-3xl font-bold">Invoice created</h2>
                                            <p className='m-10'>Created invoice with reference <b>{invoiceRef}</b>. Please check your accounting portal and confirm all details are correct before sending it.</p>
                                        </div>
                                    </Transition>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default CreateInvoiceDialog