import { baseBackEndUri } from "../settings"
import { checkAuthorized, defaultHeaders } from "./Utils"


export const getCalendars = () => {
    const requestOptions = {
        method: 'GET',
        headers: defaultHeaders
    };
    return fetch(baseBackEndUri + '/calendar', requestOptions)
}

export const listEvents = (params) => {
    let startDate = ''
    if(params.startDate){
        startDate = new Date(params.startDate).toISOString()
    }
    let endDate = ''
    if(params.endDate){
        endDate = new Date(params.endDate).toISOString()
    }
    const requestOptions = {
        method: 'GET',
        headers: defaultHeaders
    };
    return fetch(`${baseBackEndUri}/calendar/events/${params.calendar}?q=${params.q}&startDate=${startDate}&endDate=${endDate}`, requestOptions)
    .then(checkAuthorized)
}
