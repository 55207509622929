import { useState } from 'react'
import { PencilIcon, CheckIcon } from '@heroicons/react/solid'
import CreateInvoiceDialog from './CreateInvoiceDialog'

const Events = ({ className, results, scrollToSearch }) => {

    const [showCreateInvoice, setShowCreateInvoice] = useState(false)
    const [selectedItems, setSelectedItems] = useState(0)
    const [selectedEvents, setSelectEvents] = useState([])
    const createInvoice = (e) => {
        setShowCreateInvoice(true)
    }

    const trimString = (string, length) => {
        return string && string.length > length ?
            string.substring(0, length - 3) + "..." :
            string
    }

    const getDuration = (start, finish) => {
        const startDate = new Date(start.dateTime ? start.dateTime : start.date)
        const endDate = new Date(finish.dateTime ? finish.dateTime : finish.date)
        // get total seconds between the times
        let delta = Math.abs(startDate - endDate) / 1000

        // calculate (and subtract) whole days
        const days = Math.floor(delta / 86400)
        delta -= days * 86400

        // calculate (and subtract) whole hours
        const hours = Math.floor(delta / 3600) % 24
        delta -= hours * 3600

        // calculate (and subtract) whole minutes
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60
        let duration = ''
        if (days > 0) {
            duration += `${days}d`
        }
        if (hours > 0) {
            duration += `${hours}h`
        }
        if (minutes > 0) {
            duration += `${minutes}min`
        }
        return duration
    }
    const onSelect = (event) => {
        let index = parseInt(event.target.name.replace('item-', ''))
        if (event.target.checked) {
            setSelectedItems(selectedItems + 1)
            setSelectEvents([...selectedEvents, results[index]])
            event.target.closest('tr').className = 'bg-gray-100'
        } else {
            let events = [...selectedEvents]
            events = events.filter(el => el.id !== results[index].id)
            setSelectEvents(events)
            setSelectedItems(selectedItems - 1)
            event.target.closest('tr').className = ''
        }
    }

    return (
        <div className={className}>
            <CreateInvoiceDialog open={showCreateInvoice} setOpen={setShowCreateInvoice} calendarEvents={selectedEvents.length > 0 ? selectedEvents : results} />
            <div className="lg:flex lg:items-center lg:justify-between mb-10">
                <div className="flex-1 min-w-0">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                        Events found
                    </h2>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                        Found {results.length} event{results.length > 1 ? 's' : ''} matching your search.
                    </div>
                </div>
                <div className="mt-5 flex lg:mt-0 lg:ml-4">
                    <span>
                        <button type="button" className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={scrollToSearch}>
                            <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" />
                            Edit Search
                        </button>
                    </span>
                    <span className="ml-3">
                        <button type="button" disabled={results.length === 0} className={`inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${ results.length > 0 ? 'bg-indigo-600 hover:bg-indigo-700' : `bg-gray-400`}  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`} onClick={createInvoice}>
                            <CheckIcon className="-ml-1 mr-2 h-5 w-5" />
                            Create Invoice ({selectedItems === 0 ? 'All items' : `${selectedItems} item`}{selectedItems > 1 ? 's' : ''})
                        </button>
                    </span>
                </div>
            </div>
            {results.length > 0 &&
                <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Title
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Date
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Duration
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Notes
                                            </th>
                                            {/* <th scope="col" className="relative px-6 py-3"><span className="sr-only">Edit</span></th> */}
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">

                                        {results.map((result, index) => (
                                            <tr key={`result-${index}`}>
                                                <td><div className="text-center">
                                                    <input id={`item-${index}`} name={`item-${index}`} type="checkbox" onChange={onSelect} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                                    <label htmlFor={`item-${index}`} className="hidden">select</label>
                                                </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    {trimString(result.summary, 30)}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    {
                                                        new Intl.DateTimeFormat("en-GB", {
                                                            year: "numeric",
                                                            month: "short",
                                                            day: "numeric"
                                                        }).format(new Date(result.start.dateTime ? result.start.dateTime : result.start.date))
                                                    }
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    {
                                                        getDuration(result.start, result.end)
                                                    }
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    {trimString(result.description, 30)}
                                                </td>
                                                {/* <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                    <button className="text-indigo-600 hover:text-indigo-900">
                                                        <DotsHorizontalIcon className="h-6 w-6" />
                                                    </button>
                                                </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Events
