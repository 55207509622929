import { Helmet } from "react-helmet";

const Support = () => {
    return (<>
        <Helmet
            script={[{
                type: 'text/javascript',
                innerHTML: `var o_options = {
                        domain: 'okinvoices.outseta.com',
                        load: 'support',
                        support: {
                            mode: 'embed',
                            selector: '#support-form'
                        }
                    };`
            }]}
        />
        <Helmet>
            <script src="https://cdn.outseta.com/outseta.min.js"
                data-options="o_options">
            </script>
        </Helmet>
        <div id="support-form" className="mt-20 mb-20"></div>

    </>
    )
}
export default Support