import { Helmet } from "react-helmet";

const Profile = () => {
    return (<>
        <Helmet>
            <title>OK Invoices | Profile</title>
        </Helmet>
        <Helmet
            script={[{
                type: 'text/javascript',
                innerHTML: `var o_profile_options = {
                    "id": "Outseta",
                    "domain": "okinvoices.outseta.com",
                    "load": "profile",
                    "profile": {
                      "id": "profile_embed",
                      "mode": "embed",
                      "selector": "#profile-embed"
                    }
                  };`
            }]}
        />
        <Helmet>
            <script src="https://cdn.outseta.com/outseta.min.js"
                data-options="o_profile_options">
            </script>
        </Helmet>
        <div id="profile-embed" className="mt-20 mb-20"></div>

    </>
    )
}
export default Profile