
import { ExclamationIcon } from '@heroicons/react/outline'
const Message = ({ title, items }) => {
    if (items.length > 0) {
        return (
            <div className="align-bottom bg-red-100 rounded-md text-left overflow-hidden">
                <div className="px-2 pt-2 pb-2">
                    <div className="flex items-start">
                        <div className="flex-shrink-0 flex items-center justify-center h-12 w-12 mx-0 h-10 w-10">
                            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>
                        <div className="mt-2">
                            <h3 className="text-sm leading-6 font-medium text-red-700">{title}</h3>
                            <div className="mt-1">
                                <ul className="text-sm text-red-600">
                                    {items.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default Message