import Table from "./Table";
import { PlusIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import { useEffect } from "react";

const Dashboard = () => {

    useEffect(() => {
        console.log('effect')
        const token = new URLSearchParams(window.location.search).get('access_token');
        if (token && window.Outseta) {
            window.Outseta.setAccessToken(token)
        }
    }, [])

    return (
        <>
            <header>
                <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex">
                    <h1 className="text-3xl font-bold text-gray-900 flex-1">
                        Dashboard
                    </h1>
                    <div className="">
                        <Link to="/add-invoice" className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <PlusIcon className="h-6 w-6 pr-2" />
                            Invoice</Link>
                    </div>
                </div>
            </header>
            <main>
                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <div className="px-4 py-6 sm:px-0">
                        <Table />
                    </div>
                </div>
            </main>
        </>
    )
}


export default Dashboard