const FreeAgentLogo = ({ className }) => {
    return (<>
        <svg className={className} x="0px" y="0px" viewBox="0 183.4 960 193.2">
            <g>
                <g>
                    <g>
                        <path d="M255.4,221.3l-7.6,28.9c-3-1.5-6.8-2.2-11.5-2.2c-14.3,0-22.8,9.4-25.5,28.5l-8,52.4h-31.1l16.5-108.5
				h30.9L218,228c8.7-6.7,17-10,25.4-10C247.4,218,251.5,219.1,255.4,221.3z"/>
                        <path d="M357,281.7l-73.7-0.2c1.9,15.4,9.6,23.1,22.9,23.1c9.3,0,17.2-4.3,23.7-13l22,11.5
				c-14.4,17.8-30.9,26.7-49.8,26.7c-15.4,0-27.8-4.8-37.6-14.6C254.9,305.4,250,293,250,278c0-16.5,5.6-30.4,16.7-42.2
				c11.1-11.7,24.4-17.6,39.8-17.4c15.5,0,27.9,5.4,37,15.7C352.9,244.7,357.4,260.6,357,281.7z M284.8,260h43.9
				c-1.5-11.5-8.3-17.4-20.9-17.4C297.1,242.6,289.5,248.5,284.8,260z"/>
                        <path d="M472.1,281.9l-73.7-0.2c1.9,15.5,9.6,23.1,22.9,23.1c9.3,0,17-4.3,23.7-13l22,11.5
				C452.7,321.1,436,330,417.4,330c-15.4,0-27.8-4.8-37.6-14.6s-14.6-22.2-14.6-37.2c0-16.5,5.6-30.4,16.7-42.2
				c11.1-11.7,24.4-17.4,39.8-17.4c15.5,0,27.9,5.2,37,15.7C468.1,244.8,472.5,260.8,472.1,281.9z M400,260.2h43.9
				c-1.5-11.5-8.3-17.4-20.9-17.4C412.4,243,404.6,248.7,400,260.2z"/>
                        <path d="M643.7,230.4l1.9-9.1h30.5L662,316.8c-1.9,13-3.9,22.4-6.1,28.3c-2.2,5.9-5.6,11.1-9.8,15.9
				c-4.4,4.6-10.2,8.5-17.4,11.3c-7.2,3-14.8,4.4-22.4,4.3c-11.1,0-21.3-3.1-30.5-9.4s-14.8-18.3-16.5-30.5h30
				c3,11.3,9.3,15.5,17,15.5c6.7,0,12-2.6,16.3-8c4.3-5.4,6.8-12.8,7.6-22.6c-6.7,2.8-11.5,4.4-14.6,5.2c-3.1,0.7-6.8,1.3-11.1,1.3
				c-13.3,0-24.1-4.8-32.6-14.4c-8.3-9.6-12.6-22.2-12.6-37.6c0-15.5,5.2-28.9,15.5-40.2c10.4-11.3,22.6-16.8,36.5-16.8
				C622,219.3,633,223,643.7,230.4z M591,273.3c0,8.5,2.2,15.4,6.7,20.7c4.4,5.4,10.4,8,17.4,8.1c7.2,0,13.3-2.8,18.3-8.5
				c5-5.7,7.4-12.8,7.6-21.1c0-7.2-2.4-13.3-7-18.1c-4.6-4.8-10.5-7.2-17.8-7.2c-7,0-13.1,2.4-18,7.4S591,265.9,591,273.3z"/>
                        <path d="M784,282.4l-73.7-0.2c1.9,15.5,9.6,23.1,22.9,23.1c9.3,0,17-4.3,23.7-13l22,11.5
				c-14.4,17.8-31.1,26.7-49.8,26.7c-15.4,0-27.8-4.8-37.6-14.6S677,293.7,677,278.7c0-16.3,5.6-30.4,16.7-42
				c11.1-11.7,24.4-17.6,40-17.4c15.5,0,27.9,5.4,37,15.7C779.9,245.4,784.4,261.3,784,282.4z M711.8,260.8h43.9
				c-1.5-11.5-8.3-17.4-20.9-17.4C724,243.5,716.4,249.3,711.8,260.8z"/>
                        <path d="M833.8,221.7l-2.2,11.5c6.5-4.8,11.7-8.3,15.7-10.5c4.1-2.2,8.5-3.1,13.3-3.1c8.5,0,15.4,3.1,20.5,9.8
				c5.2,6.5,7.8,15,7.8,25.7c0,3.5-0.2,6.3-0.7,8.7l-9.8,65.3h-30.9l9.4-62c0.4-2.2,0.6-5,0.6-8.9c0-9.6-3.9-14.6-11.7-14.6
				c-4.8,0-8.9,2.2-12.4,6.8s-5.9,10.5-7,18l-9.3,60.5h-31.3l16.3-107.2H833.8z"/>
                        <path d="M920.4,183.4h30.9l-5.7,38.3H960l-4.4,30.9h-13.9l-11.8,76.3h-30.9l11.1-76.3h-11.7l4.6-30.9h12
				L920.4,183.4z"/>
                    </g>
                    <polygon points="520.8,218.7 492.7,218.7 513.2,281.9 483.1,281.9 479.2,306.7 521,306.3 530.8,336.6 558.9,336.6 
					"/>
                </g>
                <path d="M122.2,217.8c-0.2,0.6-2,4.8-4.8,11.8c-6.3-76.6-80-5.2-115.7-45.9C-4.4,218.6,8,223,8,223
		c-2.8,11.3,8,23.7,17,22.4c7.6,37.4,86.1-14.6,68.7,44c-16.5,41.6-34.1,86.2-34.1,86.2l36.3,0.4l30.2-77.4h37.2l5.4-34.6h-29.1
		c8-20.2,13.5-32.6,16.5-38.1c5.7-10.7,17.8-11.8,25.4-8.1l4.6-30C154.5,175.4,131.2,195.4,122.2,217.8z M94.4,247.1
		c-13.5-19.6-45.3,8.5-52.6-13.3c-8.7,1.9-11.8-8.1-9.6-11.7c0,0-7.2-1.1-6.3-11.3C51.8,228,100.1,184.1,94.4,247.1z"/>
            </g>
        </svg>
    </>
    )
}
export default FreeAgentLogo