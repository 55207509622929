import OAuth2Login from 'react-simple-oauth2-login';
import {
    freeAgentAuthorizationUrl, freeAgentClientId,
    freeAgentRedirectUri, googleAuthorizationUrl,
    googleClientId, googleRedirectUri, baseBackEndUri,
    xeroClientId, xeroRedirectUri, xeroAuthorizationUrl,
    quickbooksAuthorizationUrl, quickbooksClientId, quickbooksRedirectUri
} from "./settings"
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getGoogleToken, getFreeAgentToken, deleteToken, getXeroToken, getQuickbooksToken } from './api/Auth';
import Message from './elements/Message';

const Account = () => {

    const [googleUser, setGoogleUser] = useState()
    const [freeAgentUser, setFreeAgentUser] = useState()
    const [xeroUser, setXeroUser] = useState()
    const [quickbooksUser, setQuickbooksUser] = useState()
    const [searchParams] = useSearchParams();
    let [finishSetup] = useState(
        searchParams.get("finishSetup")
    );

    useEffect(() => {
        getFreeAgentToken().then(response => {
            if (response.ok) {
                response.json().then(data => {
                    return data
                }).then(data => setFreeAgentUser(true))
            }
        })
        getGoogleToken().then(response => {
            if (response.ok) {
                response.json().then(data => {
                    return data
                }).then(data => setGoogleUser(true))
            }
        })
        getXeroToken().then(response => {
            if (response.ok) {
                response.json().then(data => {
                    return data
                }).then(data => setXeroUser(true))
            }
        })
        getQuickbooksToken().then(response => {
            if (response.ok) {
                response.json().then(data => {
                    return data
                }).then(data => setQuickbooksUser(true))
            }
        })
    }, []);

    const onFailure = response => {
        console.log('fail')
        console.error(response);
        alert("We couldn't complete your request. Please try again.")
    }

    const onSuccessGoogle = response => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + window.Outseta.getAccessToken() },
            body: JSON.stringify(response)
        }
        fetch(baseBackEndUri + '/auth/google', requestOptions)
            .then(response => response.json())
            .then(() => setGoogleUser(true))
    }

    const onSuccess = response => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + window.Outseta.getAccessToken() },
            body: JSON.stringify(response)
        };
        fetch(baseBackEndUri + '/auth/freeagent', requestOptions)
            .then(response => response.json())
            .then(data => setFreeAgentUser(true));
    }


    const onSuccessXero = response => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + window.Outseta.getAccessToken() },
            body: JSON.stringify(response)
        };
        fetch(baseBackEndUri + '/auth/xero', requestOptions)
            .then(response => response.json())
            .then(data => setXeroUser(true));
    }

    const onSuccessQuickbooks = response => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + window.Outseta.getAccessToken() },
            body: JSON.stringify(response)
        };
        fetch(baseBackEndUri + '/auth/quickbooks', requestOptions)
            .then(response => response.json())
            .then(data => setQuickbooksUser(true));
    }

    const unlink = e => {
        e.preventDefault()
        const service = e.target.dataset.service
        deleteToken(service).then(response => {
            if (service === 'google') {
                setGoogleUser(null)
            } else if (service === 'freeagent') {
                setFreeAgentUser(null)
            } else if (service === 'xero') {
                setXeroUser(null)
            } else if (service === 'quickbooks') {
                setQuickbooksUser(null)
            }
        })
    }

    return (
        <>
            <header>
                {finishSetup && (
                    <div className='py-5 md:py-5  m-auto md:w-2/5 w-11/12'>
                        <Message title='Please finish setup' items={['Please connect your Calendar and Accounting services']} />
                    </div>
                )}
                <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex">
                    <h1 className="text-3xl font-bold text-gray-900 flex-1">
                        Settings
                    </h1>
                </div>
            </header>
            <main>
                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <div>
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Calendar</h3>
                                    <p className="mt-1 text-sm text-gray-600">
                                        Link your account to your calendar provider
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5 md:mt-0 md:col-span-2">
                                <div className="shadow sm:rounded-md sm:overflow-hidden">
                                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                        <div className="grid grid-cols-3 gap-6">
                                            <div className="col-span-3 sm:col-span-2">
                                                <p className="block text-sm font-medium text-gray-700">
                                                    Google Calendar
                                                </p>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    {!googleUser && (
                                                        <OAuth2Login
                                                            authorizationUrl={googleAuthorizationUrl}
                                                            responseType="code"
                                                            clientId={googleClientId}
                                                            scope="https://www.googleapis.com/auth/calendar.readonly"
                                                            redirectUri={googleRedirectUri}
                                                            extraParams={{ access_type: 'offline' }}
                                                            onSuccess={onSuccessGoogle}
                                                            onFailure={onFailure}>
                                                            <img src="img/btn_google_signin_light_normal_web@2x.png" alt="Sign in Google" width="191px" />
                                                        </OAuth2Login>
                                                    )}
                                                    {googleUser && (
                                                        <>
                                                            <img src="img/btn_google_signin_dark_disabled_web@2x.png" alt="Sign in Google" width="191px" />
                                                            <a href="#unlink" className='text-gray-500 pt-2 pl-2' onClick={unlink} data-service="google">(unlink)</a>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="hidden sm:block" aria-hidden="true">
                        <div className="py-5">
                            <div className="border-t border-gray-200" />
                        </div>
                    </div>

                    <div className="mt-10 sm:mt-0">
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Accounting platorm</h3>
                                    <p className="mt-1 text-sm text-gray-600">Link your account to your accounting platform</p>
                                </div>
                            </div>
                            <div className="mt-5 md:mt-0 md:col-span-2">
                                <div className="shadow sm:rounded-md sm:overflow-hidden">
                                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                        <div className="grid grid-cols-3 gap-6">
                                            <div className="col-span-3 sm:col-span-2">
                                                <p className="block text-sm font-medium text-gray-700">
                                                    FreeAgent
                                                </p>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    {!freeAgentUser && !xeroUser && !quickbooksUser && (
                                                        <OAuth2Login
                                                            authorizationUrl={freeAgentAuthorizationUrl}
                                                            responseType="code"
                                                            clientId={freeAgentClientId}
                                                            scope=""
                                                            redirectUri={freeAgentRedirectUri}
                                                            onSuccess={onSuccess}
                                                            onFailure={onFailure}>
                                                            <img src="/img/freeagent-button.png" alt="Sign in with FreeAgent" width="191px" />
                                                        </OAuth2Login>
                                                    )}
                                                    {(freeAgentUser || xeroUser || quickbooksUser) && (
                                                        <>
                                                            <img src="/img/btn_freeagent_signin_dark_disabled.png" alt="Sign in with FreeAgent Disabled" width="191px" />
                                                            {freeAgentUser && (
                                                                <a href="#unlink" className='text-gray-500 pt-2 pl-2' onClick={unlink} data-service="freeagent">(unlink)</a>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            {/* <div className="col-span-3 sm:col-span-2">
                                                <p className="block text-sm font-medium text-gray-700">
                                                    Xero
                                                </p>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    {!xeroUser && !freeAgentUser && !quickbooksUser && (
                                                        <OAuth2Login
                                                            authorizationUrl={xeroAuthorizationUrl}
                                                            responseType="code"
                                                            clientId={xeroClientId}
                                                            scope="offline_access accounting.transactions accounting.contacts.read accounting.settings.read"
                                                            redirectUri={xeroRedirectUri}
                                                            onSuccess={onSuccessXero}
                                                            onFailure={onFailure}>
                                                            <img src="/img/btn_xero_signin_light_normal_web@2x.png" alt="Sign in with Xero" width="191px" />
                                                        </OAuth2Login>
                                                    )}
                                                    {(freeAgentUser || xeroUser || quickbooksUser) && (
                                                        <>
                                                            <img src="/img/btn_xero_signin_dark_disabled_web@2x.png" alt="Sign in with Xero Disabled" width="191px" />
                                                            {xeroUser && (
                                                                <a href="#unlink" className='text-gray-500 pt-2 pl-2' onClick={unlink} data-service="xero">(unlink)</a>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div> */}
                                            <div className="col-span-3 sm:col-span-2">
                                                <p className="block text-sm font-medium text-gray-700">
                                                    Quickbooks
                                                </p>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    {!quickbooksUser && !xeroUser && !freeAgentUser && (
                                                        <OAuth2Login
                                                            authorizationUrl={quickbooksAuthorizationUrl}
                                                            responseType="code"
                                                            clientId={quickbooksClientId}
                                                            scope="com.intuit.quickbooks.accounting"
                                                            state="okinvoices"
                                                            redirectUri={quickbooksRedirectUri}
                                                            onSuccess={onSuccessQuickbooks}
                                                            onFailure={onFailure}>
                                                            <img src="/img/C2QB_green_btn_tall_default_2x.png" alt="Connect to Quickbooks" width="191px" />
                                                        </OAuth2Login>
                                                    )}
                                                    {(freeAgentUser || xeroUser || quickbooksUser) && (
                                                        <>
                                                            <img src="/img/C2QB_transparent_btn_tall_hover2x.png" alt="Connect to Quickbooks Disabled" width="191px" />
                                                            {quickbooksUser && (
                                                                <a href="#unlink" className='text-gray-500 pt-2 pl-2' onClick={unlink} data-service="quickbooks">(unlink)</a>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default Account