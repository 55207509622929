const LoadingSkeleton = () => {

    return (
        <>
            <div className="animate-pulse h-fullspace-x-5">
                <div className="w-36 ml-6 bg-gray-200 h-6 rounded-md ">
                </div>
                <div className="w-72 mt-2 ml-6 bg-gray-200 h-6 rounded-md ">
                </div>
                <div className="w-96 mt-5 ml-2 bg-gray-200 h-40 rounded-md ">
                </div>
            </div>
        </>
    )
}
export default LoadingSkeleton