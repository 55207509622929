const TextInput = ({ label, id, name, value, autoComplete, error, required, requiredErrorMessage, register }) => {

    return (
        <>
            <label htmlFor={id} className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <input
                id={id}
                {...register(name, { required })}
                type="text"
                value={value}
                autoComplete={autoComplete}
                className={`mt-1 block w-full py-2 px-3 border ${error ? 'border-red-600' : 'border-gray-300'} bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            />
            {error && (
                <div className="mt-1 text-sm text-red-600 ">{requiredErrorMessage}</div>
            )}
        </>
    )
}
export default TextInput