const Dropdown = ({ label, id, name, value, autoComplete, items, error, requiredErrorMessage, register, required }) => {

    return (
        <>
            <label htmlFor={id} className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <select
                id={id}
                {...register(name, { required })}
                value={value}
                autoComplete={autoComplete}
                className={`mt-1 block w-full py-2 px-3 border ${error ? 'border-red-600' : 'border-gray-300'} bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            >
                {items.map((item, index) => (
                    <option key={`${id}-${index}`} disabled={item.disabled} value={item.value}>{item.text}</option>
                ))}
            </select>
            {error && (
                <div className="text-sm font-medium text-red-600 ">{requiredErrorMessage}</div>
            )}
        </>
    )
}
export default Dropdown