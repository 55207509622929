import { Helmet } from "react-helmet";
import { baseUrl } from "../settings";

const Login = () => {
    return (<>
        <Helmet>
            <title>OK Invoices | Login</title>
        </Helmet>
        <Helmet
            script={[{
                type: 'text/javascript',
                innerHTML: `var o_login_options = {
                    "id": "Outseta",
                    "domain": "okinvoices.outseta.com",
                    "load": "auth",
                    "auth": {
                        "widgetMode": "login",
                        "id": "login_embed",
                        "mode": "embed",
                        "selector": "#login-embed",
                        "authenticationCallbackUrl": "${baseUrl}/invoice"
                    }
                    };`
            }]}
        />
        <Helmet>
            <script src="https://cdn.outseta.com/outseta.min.js"
                data-options="o_login_options">
            </script>
        </Helmet>
        <div id="login-embed" className="mt-20 mb-20"></div>
    </>
    )
}
export default Login