import { useState, useEffect, useRef } from 'react'
import { CalendarIcon, FilterIcon } from "@heroicons/react/outline"
import { SearchIcon } from '@heroicons/react/solid'
import Events from './Events.js'
import { getCalendars, listEvents } from './api/Calendars'
import { useForm } from 'react-hook-form'
import { getStatus } from './api/Auth.js'
import { useNavigate } from 'react-router-dom'
import LoadingSkeleton from './elements/LoadingSkeleton.js'
import Message from './elements/Message.js'
import DatePicker from './elements/DatePicker.js'

const Invoice = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [calendars, setCalendars] = useState([])
    const [result, setResult] = useState({
        received: false,
        results: []
    })
    const [loaded, setLoaded] = useState(false)
    const [serverError, setServerError] = useState(false)
    const eventsRef = useRef(null)
    const searchRef = useRef(null)
    const navigate = useNavigate()

    useEffect(() => {
        getStatus().then(response => {
            if (response.ok) {
                response.json().then(data => {
                    if (data.hasCalendar && data.hasAccounting) {
                        setLoaded(true)
                    } else {
                        navigate('/settings?finishSetup=true')
                    }
                })
            }
        }).catch(error => {
            setServerError(true)
            console.log(error)
        })
    }, [navigate]);

    useEffect(() => {
        getCalendars().then(response => response.json())
            .then(data => data.result.map(entry => ({ label: entry.summaryOverride ? entry.summaryOverride : entry.summary, value: entry.id })))
            .then(data => setCalendars(data))
            .catch(error => {
                setServerError(true)
                console.log(error)
            })

    }, []);

    const onSubmit = (data) => {
        listEvents(data).then(response => response.json())
            .then(data => setResult({ received: true, results: data.result }))
            .then(() => eventsRef.current.scrollIntoView({ behavior: 'smooth' }))
    }

    const scrollToSearch = () => {
        searchRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    if (serverError) {
        return (
            <div className="p-10 pt-5">
                <Message title="We can't connect to the server" items={['Check if your internet is working and refresh this page.']} />
            </div>
        )
    } else if (loaded && calendars.length > 0) {
        return (
            <>
                <header>
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900 flex-1">
                            Create Invoices
                        </h1>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">Create one or multiple invoices from the events in your calendar. Please use the form below to filter the events you would like to invoice.</p>
                    </div>
                </header>
                <main>
                    <div className="max-w-7xl mx-auto">
                        <div className="p-4 lg:p-7 sm:p-6 pt-2" ref={searchRef}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mt-5 md:mt-0 md:col-span-2">
                                    <div className="shadow overflow-hidden rounded-md">
                                        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                            <fieldset>
                                                <legend className="text-base font-medium text-gray-900 flex"><CalendarIcon className="w-4 mr-1" /> Calendars</legend>
                                                <p className="text-sm text-gray-500">Choose the calendar from which you want to search events to invoice.</p>
                                                <div className="mt-4 space-y-2">

                                                    {errors.calendar && (
                                                        <div className="text-sm font-medium text-red-600 ">{errors.calendar.message}</div>
                                                    )}
                                                    {calendars.map(({ label, value }) => (
                                                        <div className="flex items-start" key={value}>
                                                            <div className="flex items-center h-5">
                                                                <input
                                                                    id={value}
                                                                    value={value}
                                                                    {...register('calendar', { required: 'Please select a calendar' })}
                                                                    type="radio"
                                                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                                                />
                                                            </div>
                                                            <div className="ml-3 text-sm">
                                                                <label htmlFor={value} className="font-medium text-gray-700">
                                                                    {label}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </fieldset>
                                            <fieldset>
                                                <div>
                                                    <legend className="text-base font-medium text-gray-900 flex"><FilterIcon className="w-4 mr-1" /> Filters</legend>
                                                </div>
                                                <div className="grid grid-cols-6 gap-6 mt-4">
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <DatePicker label='Start Date'
                                                            id='start-date'
                                                            name='startDate'
                                                            register={register}
                                                            autoComplete='off'
                                                            placeholder="Select date (YYYY-MM-DD)"
                                                        />
                                                    </div>

                                                    <div className="col-span-6 sm:col-span-3">
                                                        <DatePicker label='End Date'
                                                            id='end-date'
                                                            name='endDate'
                                                            register={register}
                                                            autoComplete='off'
                                                            placeholder="Select date (YYYY-MM-DD)"
                                                        />
                                                    </div>
                                                    <div className="col-span-6">
                                                        <label htmlFor="keywords" className="block text-sm font-medium text-gray-700">
                                                            Keywords
                                                        </label>
                                                        <input
                                                            type="text"
                                                            {...register('q')}
                                                            id="keywords"
                                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        />
                                                    </div>
                                                </div>

                                            </fieldset>
                                        </div>
                                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <button
                                                type="submit"
                                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                <SearchIcon className="-ml-1 mr-2 h-5 w-5" />
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div ref={eventsRef}>

                                {result.received &&
                                    <Events className="mt-10" results={result.results} scrollToSearch={scrollToSearch} />
                                }

                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    } else {
        return (
            <div className='py-10 px-7'>
                <LoadingSkeleton />
            </div>
        )
    }
}
export default Invoice