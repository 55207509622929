import { baseBackEndUri } from "../settings"
import { checkAuthorized, defaultHeaders } from "./Utils"

export const getClients = async () => {
    const requestOptions = {
        method: 'GET',
        headers: defaultHeaders
    };
    const response = await fetch(baseBackEndUri + '/accounting/client', requestOptions);
    return checkAuthorized(response);
}

export const invoice = async (data) => {
    const requestOptions = {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify(data)
    };
    const response = await fetch(baseBackEndUri + '/accounting/invoice', requestOptions);
    return checkAuthorized(response);
}

