import { Helmet } from "react-helmet";

const GenericNotFound = () => {
    return (<>
        <Helmet>
            <title>OK Invoices | 404</title>
        </Helmet>
        <div className="px-2 pt-20 pb-20 md:pt-32 md:pb-32 bg-white md:px-0">
            <h1 className="text-indigo-600 text-5xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl text-center">404</h1>
            <p className="text-5xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl text-center">Page not found</p>
        </div>
    </>
    )
}
export default GenericNotFound