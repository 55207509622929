export const baseBackEndUri = process.env.REACT_APP_BACKEND_URL
export const freeAgentClientId = process.env.REACT_APP_FREEAGENT_CLIENT_ID
export const freeAgentRedirectUri = process.env.REACT_APP_FREEAGENT_REDIRECT_URL
export const freeAgentAuthorizationUrl = process.env.REACT_APP_FREEAGENT_AUTHORIZATION_URL
export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
export const googleRedirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URL
export const googleAuthorizationUrl = process.env.REACT_APP_GOOGLE_AUTHORIZATION_URL
export const baseUrl = process.env.REACT_APP_ROOT
export const xeroClientId=process.env.REACT_APP_XERO_CLIENT_ID
export const xeroRedirectUri = process.env.REACT_APP_XERO_REDIRECT_URL
export const xeroAuthorizationUrl = process.env.REACT_APP_XERO_AUTHORIZATION_URL
export const quickbooksClientId=process.env.REACT_APP_QUICKBOOKS_CLIENT_ID
export const quickbooksRedirectUri = process.env.REACT_APP_QUICKBOOKS_REDIRECT_URL
export const quickbooksAuthorizationUrl = process.env.REACT_APP_QUICKBOOKS_AUTHORIZATION_URL
