import { Helmet } from "react-helmet";
import { useSearchParams } from 'react-router-dom'
import { useState } from 'react'

const SignUp = () => {
    const [searchParams] = useSearchParams();
    let [plan] = useState(
        searchParams.get("plan")
    );

    if (plan === 'premium' || plan === 'free') {
        return (<>
            <Helmet>
                <title>OK Invoices | Signup</title>
            </Helmet>
            <Helmet
                script={[{
                    type: 'text/javascript',
                    innerHTML: `var o_signup_options = {
                        "id": "Outseta",
                        "domain": "okinvoices.outseta.com",
                        "load": "auth",
                        "auth": {
                          "widgetMode": "register",
                          "planUid": "vWydG19b",
                          "planPaymentTerm": "annual",
                          "skipPlanOptions": true,
                          "id": "signup_embed",
                          "mode": "embed",
                          "selector": "#signup-embed"
                        }
                      };`
                }]}
            />
            <Helmet>
                <script src="https://cdn.outseta.com/outseta.min.js"
                    data-options="o_signup_options">
                </script>
            </Helmet>
            <div id="signup-embed" className="mt-20 mb-20"></div>

        </>)
    } else {
        return (<>
            <Helmet
                script={[{
                    type: 'text/javascript',
                    innerHTML: 'var o_signup_options = {"id": "Outseta", "domain": "okinvoices.outseta.com", "load": "auth", "auth": { "widgetMode": "register", "planFamilyUid": "yWoOgPWD","skipPlanOptions": true,"id": "signup_embed","mode": "embed","selector": "#signup-embed"}};'
                }]}
            />
            <Helmet>
                <script src="https://cdn.outseta.com/outseta.min.js"
                    data-options="o_signup_options">
                </script>
            </Helmet>
            <div id="signup-embed" className="mt-20"></div>

        </>
        )
    }
}
export default SignUp