export const checkAuthorized = (response) => {
    if (response.status === 401) {
        window.location.href = '/login'
    }
    return response
}

const getAccessToken = () => {
    if (window.Outseta) {
        const token = window.Outseta.getAccessToken()
        if (token) {
            return token;
        } else {
            const urlParams = new URLSearchParams(window.location.search)
            return urlParams.get('access_token')
        }
    }
    return undefined
}

export const defaultHeaders = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getAccessToken() }